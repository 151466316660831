/* Header */

.header-earth {
  height: 6.5em;
  margin-top: .25em;
  width: max-content;
}

  .header-paper {
    margin: 1em;
    margin-bottom: .5em;
  }
  
  .header-headline  {
    font-size: 60px;
    color:black;
    margin-top: .5em;
    text-shadow:
    /* 0px -2px 4px #fff, */
    0px -2px 10px #FF3,
    0px -10px 20px #F90,
    0px -20px 40px #C33;
  }

  .header-sub-headline {
    font-size: 26px;
    color: black;
    margin-top: -.5em;
  }

  .header-patreon-link {
    cursor: url('../../assets/cursor/sym57.cur'), auto;
  }

  .header-patreon-link:visited {
    color: black;
  }


/* begin media queries for responsve sizing */
/* @media screen and (max-width: 1200px) {
  
} */

@media screen and (max-width: 900px) {

  .header-earth {
    height: 5.5em;
    margin-top: 0.2em;
  }

  .header-headline  {
    font-size: 54px;
  }

  .header-sub-headline {
    font-size: 22px;
    margin-top: 0em;
  }
}

@media screen and (max-width: 600px) {

  .header-earth {
    height: 5em;
  }

  .header-headline  {
    font-size: 42px;
  }

  .header-sub-headline {
    font-size: 22px;
    margin-top: 0em;
  }
}

@media screen and (max-width: 484px) {

}

@media screen and (max-width: 420px) {

  .header-headline  {
    font-size: 28px;
  }

  .header-earth {
    height: 3em;
  }

  .header-sub-headline {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {

  .header-earth {
    height: 2.8em;
  }

  .header-headline  {
    font-size: 26px;
  }

  .header-sub-headline {
    font-size: 14px;
  }
}

@media screen and (max-width: 300px) {

  .header-earth {
    height: 2.5em; 
  }

  .header-headline  {
    font-size: 20px;
  }

  .header-sub-headline {
    font-size: 12px;
  }
}