#tour-headline {
  font-size: 48px;
  text-align: center;
  color: black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

.tour-dates {
  font-size: 36px;
  text-align: center;
  color: black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}


.tour-description-text {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 24px;
    text-shadow:
    /* 0px -2px 4px #fff, */
    0px -2px 10px #FF3,
    0px -10px 20px #F90,
    0px -20px 40px #C33;
}

.tour-marquee {
  margin-top: .5em;
}

.tour-marquee-text {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: .5em;
  margin-bottom: .5em;
  color: blue;
}

/* .marquee-patreon-link {
  color: red;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
} */


/* @media screen and (max-width: 900px) {
  
} */

@media screen and (max-width: 600px) {
  #tour-headline {
    font-size: 42px;
  }
  
  .tour-dates {
    font-size: 32px;
  }
  
  .tour-description-text {
    font-size: 22px;
  }
  
}

@media screen and (max-width: 484px) {
  #tour-headline {
    font-size: 40px;
  }
  
  .tour-dates {
    font-size: 30px;
  }
  
  .tour-description-text {
    font-size: 20px;
  }
  
}


@media screen and (max-width: 400px) {
  #tour-headline {
    font-size: 38px;
  }
  
  .tour-dates {
    font-size: 28px;
  }
  
  .tour-description-text {
    font-size: 16px;
  }
  
}

@media screen and (max-width: 300px) {
  #tour-headline {
    font-size: 30px;
  }
  
  .tour-dates {
    font-size: 26px;
  }
  
  .tour-description-text {
    font-size: 14px;
  }
 
}