footer {
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* padding-bottom: .5em; */
    padding-top: .75em;
    position: relative;
    left: 0;
    bottom: 0; 
    font-size: 14px;
}

.follow-text {
  color: black;
  font-size: 16px;
}

.social-media-follow-links {
  color: blue;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}

.social-media-follow-links:active {
  color: black;
}

.copywrite {
  color: black;
}

.website-credit {
  color: blue;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}


  