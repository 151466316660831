.vid-patreon-link {
  color: black;
  font-size: 20px;
  text-shadow: 
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
  font-weight: bold;
  text-decoration: none;
}

.video-share-box {
  margin-bottom: 1em;
}

.vid-share-paper {
  height: 5.5em;
}

.video-title {
  color: black;
  font-size: 24px;
  padding-top: 0em;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.video-original-artist {
  color: black;
  font-size: 24px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-top: -1.35em;
}

.video-location {
  color: black;
  font-size: 24px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-top: -1.6em;
}

#video-gallery-section {
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.nation-flag {
  outline: solid;
  outline-width: 1px;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-top: -1em;
    margin-bottom: -1em;
  }

.video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.nation-flag {
  margin-top: .5em;
  margin-bottom: -.5em;
}

.share-video-text {
  margin-top: .5em;
}

.vid-social-media-share-links {
  margin-left: .2em;
  margin-right: .2em;
  color: black;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}

.video-caption {
  color: black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

@media screen and (max-width: 320px) {
  .vid-patreon-link {
    Font-size: 18px;
  }
}

@media screen and (max-width: 300px) {
  #video-title {
    font-size: 1.25em;
  }
  
  #video-original-artist {
    font-size: 1.25em;
  }
  
  #video-location {
    font-size: 1.25em;
  }
  
}






@media screen and (max-width: 280px) {
  .vid-patreon-link {
    Font-size: 16px;
  }
}