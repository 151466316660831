#album-headline {
  font-size: 48px;
  text-align: center;
  color: black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}
  
#album-sub-headline {
  font-size: 42px;
  color: black;
  text-align: center;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

#album-headline-plain-text {
  font-size: 28px;
  color: black;
  margin-bottom: .25em;
  text-align: center;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

.album-patreon-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-shadow:
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}


/* .album-marquee-text {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: .5em;
  margin-bottom: .5em;
  color: blue;
} */

/* .marquee-patreon-link {
  color: red;
} */

@media screen and (max-width: 900px) {
  /* #album-headline {
    font-size: 48px;
  }
    
  #album-sub-headline {
    font-size: 42px;
  }
  
  #album-headline-plain-text {
    font-size: 24px;
  } */

}

/* @media screen and (max-width: 600px) {

} */

@media screen and (max-width: 484px) {
  #album-headline {
    font-size: 42px;
  }
    
  #album-sub-headline {
    font-size: 36px;
  }
  
  #album-headline-plain-text {
    font-size: 24px;
  }
  
}


@media screen and (max-width: 420px) {
  #album-headline {
    font-size: 36px;
  }
    
  #album-sub-headline {
    font-size: 32px;
  }
  
  #album-headline-plain-text {
    font-size: 24px;
  }
}

/* @media screen and (max-width: 400px) {

} */

@media screen and (max-width: 300px) {
  #album-headline {
    font-size: 30px;
  }
    
  #album-sub-headline {
    font-size: 24px;
  }
  
  #album-headline-plain-text {
    font-size: 20px;
  }
}