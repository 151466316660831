nav {
  margin-top: .5em;
  margin-bottom: .5em;
}

nav li {
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}

.nav-list-item {
  list-style: none;
  font-size: 36px;
  color: blue;
}

/* #nav-list-item:hover {
  text-shadow:
    0px -2px 10px #FF3,
    0px -10px 20px #F90,
    0px -20px 40px #C33;
} */

.navActive {
  text-shadow: 0 0 10px blue;
  font-weight: bold;
}

.nav-patreon-link {
  text-decoration: none;
}

/* begin media queries for responsve sizing */
@media screen and (max-width: 900px) {
  .nav-list-item {
    font-size: 32px;
  }
}

@media screen and (max-width: 600px) {
  .nav-list-item {
    font-size: 28px;
  }
}

@media screen and (max-width: 420px) {
  .nav-list-item {
  font-size: 20px;
  }
}
