#mainstream-div h2 {
  font-size: 48px;
  color: black;
  /* text-shadow: 0 0 10px blue; */
  margin-bottom: .25em;
  text-align: center;
}

.mainstream-paper {
  background-size: 24em;
  background-repeat: no-repeat;
  background-position: 50% 10%;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 0em;
  height:65.4em;
}

#mainstream-patreon-link {
  /* text-decoration: none; */
  cursor: url('../../assets/cursor/sym57.cur'), auto;
  color:black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

#mainstream-patreon-link:visited {
  color: black;
}

.mainstream-marquee-text {
  font-size: 16.5px;
  font-weight: bold;
  margin-top: .5em;
  margin-bottom: .5em;
  color: blue;
}

.announcement-grid {
  margin-top: 20em;
  margin-left: 1em;
}

.announcement-plain-text{
  font-size: 28px;
  color: black;
  font-weight: bold;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

.announcement-headline {
  font-size: 48px;
  color: black;
  /* font-weight: bold; */
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;			
}

.announcement-sub-headline {
  font-size: 42px;
  color: black;
  text-decoration: none;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

.announcement-patreon-link{
  cursor: url('../../assets/cursor/sym57.cur'), auto;
  text-decoration: none;
}

/* guitarists */
/* guitarist head */
.guitarist-skull{
  /* height: 3.25em; */
  margin-top: 0em;
  margin-bottom: -11.15em;
  z-index: 2;
  position: relative;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .guitarist-skull {
    animation: guitarist-head-nod infinite alternate .70s linear;
  }
}

@keyframes guitarist-head-nod {
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}

/* guitarist bodies */
.guitarist-body {
  height: 12em;
  margin-top: 0em;
  margin-bottom: -15.2em;
  width: max-content;
}


@media (prefers-reduced-motion: no-preference) {
  .guitarist-body {
    animation: skeleton-guitarist-body-rock infinite alternate-reverse .70s linear;
  }
}

@keyframes skeleton-guitarist-body-rock {
  from {
    transform: rotate(-2deg);
    /* transform: rotate(0deg); */
  }
  to {
    transform: rotate(2deg);
    /* transform: rotate(0deg); */
  }
}

/* guitarist right arm */
.guitarist-right-arm {
  height: 5.5em;
  margin-top: 3em;
  margin-bottom: 0em;
  margin-left: -5.5em;
  position: relative;
  z-index: 3;
  width: max-content;
}


@media (prefers-reduced-motion: no-preference) {
  .guitarist-right-arm {
    animation: guitar-right-arm-strum infinite alternate .70s linear;
  }
}

@keyframes guitar-right-arm-strum {
  from {
    transform: rotate(10deg);
    /* transform: rotate(-14deg); */
  }
  to {
    transform: rotate(-14deg);
  }
}

.guitarist-left-arm {
  height: 4.25em;
  margin-top: -6.1em;
  margin-bottom: 4em;
  margin-left: 9.25em;
  position: relative;
  z-index: 3;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .guitarist-left-arm {
    animation: guitar-left-arm-strum infinite  alternate .70s linear;
  }
}

@keyframes guitar-left-arm-strum {
  from {
    /* transform: rotate(2deg); */
    transform: rotate(6deg);
  }
  to {
    transform: rotate(1deg);
    /* transform: rotate(6deg); */
  }
}

/* guitars */
.mainstream-guitar{
  height: 4.65em;
  margin-top: -20.5em;
  margin-bottom: 21em;
  margin-left: 1.75em; 
  z-index: 2;
  position:relative;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .mainstream-guitar {
    animation: mainstream-guitar-spin infinite alternate .70s linear;
  }
}

@keyframes mainstream-guitar-spin {
  from {
    transform: rotate(-14deg);
    /* transform: rotate(-6deg); */
  }
  to {
    transform: rotate(-6deg);
    /* transform: rotate(-14deg); */
  }
}

/* drummer */
#mohawk-skull {
  height: 7.5em;
  margin-top: 5em;
  margin-bottom: -6em;
  margin-left: -.55em;
  z-index: 2;
  position: relative;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  #mohawk-skull {
    animation: mohawk-head-nod infinite alternate .70s linear;
  }
}

@keyframes mohawk-head-nod {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}

.drummer-body {
  height: 6.8em;
  margin-top: 0em;
  margin-bottom: -10.75em;
  /* margin-left: -0.2em; */
  z-index: 1;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .drummer-body{
    animation: drummer-body-rock infinite alternate-reverse .70s linear;
  }
}

@keyframes drummer-body-rock {
  from {
    transform: rotate(8deg);
    /* transform: rotate(0deg); */
  }
  to {
    transform: rotate(-8deg);
    /* transform: rotate(0deg); */
  }
}

.drummer-right-arm {
  height: 7em;
  margin-top: -10.55em;
  margin-bottom: 11em;
  margin-left: 1em;
  position: relative;
  z-index: 3;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .drummer-right-arm{
    animation: drummer-right-arm-rock infinite alternate-reverse .70s linear;
  }
}

@keyframes drummer-right-arm-rock {
  from {
    transform: rotate(-180deg);
    /* transform: rotate(-60deg); */
  }
  to {
    transform: rotate(-60deg);
    /* transform: rotate(-180deg); */
  }
}

.drummer-left-arm {
  height: 5.6em;
  margin-top: -17.18em;
  margin-bottom: 17em;
  margin-left: 1.88em;
  position: relative;
  z-index: 2;
  width: max-content;
}

@media (prefers-reduced-motion: no-preference) {
  .drummer-left-arm{
    animation: drummer-left-arm-rock infinite alternate .70s linear;
  }
}

@keyframes drummer-left-arm-rock {
  from {
    transform: rotate(50deg);
    /* transform: rotate(30deg); */
  }
  to {
    transform: rotate(30deg);
    /* transform: rotate(50deg); */
  }
}

/* drums */
.drums {
  height: 12.5em;
  margin-top: -29.65em;
  margin-bottom: 20em;
  /* padding-bottom: 0em; */
  position: relative;
  z-index: 4;
  width: max-content;
}

/* audio-player */
.mainstream-react-h5-audio-player {
  margin-top: -20em;
  margin-bottom: 0em;
  outline: black solid 2px;
  position: relative;
  /* position: absolute; */
  height: 7.25em;
}

/* cursor */
.rhap_container svg  {
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}


/* begin mediq queries for responsve sizing */
/* @media screen and (max-width: 1200px) {
} */

/* @media screen and (max-width: 1000px) {

  
} */

@media screen and (max-width: 875px) {
  .mainstream-paper {
    /* background-size: 22em; */
    /* background-position: 50% 10%; */
    height: 63em;
  }

  /* guitarist */
  .guitarist-skull{
    height: 4.5em; 
    margin-top: 0em;
    margin-bottom: -9.45em;
  }

  .guitarist-body {
    height: 11.5em;
    margin-top: 0em;
    margin-bottom: -14em;
  }

  .guitarist-right-arm {
    height: 5em;
    margin-top: 2.25em;
    margin-bottom: 0em;
    margin-left: -5em;
  }

  .guitarist-left-arm {
    height: 4em;
    margin-top: -5.5em;
    margin-bottom: 3em;
    margin-left: 8.5em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 4.45em;
    margin-top: -10.3em;
    margin-bottom: 7em;
    margin-left: 1.5em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 7em;
    margin-top: 5em;
    margin-bottom: -6.5em;
    /* margin-left: -.15em; */
  }

  .drummer-body {
    height: 6.45em;
    margin-top: 0em;
    margin-bottom: -10.85em;
  }

  .drummer-right-arm {
    height: 6.5em;
    margin-top: -8.35em;
    margin-bottom: 4em;
    margin-left: 1em;
  }

  .drummer-left-arm {
    height: 5em;
    margin-top: -9.34em;
    margin-bottom: 6em;
    margin-left: 2.25em;
  }

  /* drums */
  .drums {
    height: 10em;
    margin-top: -13.5em;
    margin-bottom: 8em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -7.8em;
    margin-bottom: 0em;
    height: 7em;
  }

} 

@media screen and (max-width: 700px) {

  .mainstream-paper {
    background-size: 22em;
    background-position: 50% 5%;
    height: 51.75em;
  }

  #mainstream-div h2 {
    font-size: 36px;
  }

  .announcement-grid {
    margin-top: 18em;
    /* margin-left: 1em; */
  }
  
  .announcement-plain-text{
    font-size: 22px;
  }
  
  .announcement-headline {
    font-size: 42px;
  }
  
  .announcement-sub-headline {
    font-size: 36px;
  }

  /* guitarist */
  .guitarist-skull{
    height: 3.25em; 
    margin-top: 0em;
    margin-bottom: -6.2em;
  }

  .guitarist-body {
    height: 7.75em;
    margin-top: 0em;
    margin-bottom: -10.55em;
  }

  .guitarist-right-arm {
    height: 3.5em;
    margin-top: 2.45em;
    margin-bottom: 0em;
    margin-left: -3.5em;
  }

  .guitarist-left-arm {
    height: 2.8em;
    margin-top: -4.1em;
    margin-bottom: 3em;
    margin-left: 6em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 3em;
    margin-top: -9.5em;
    margin-bottom: 9.5em;
    margin-left: 1.25em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 4.75em;
    margin-top: 4em;
    margin-bottom: -6.5em;
    margin-left: -.4em;
  }

  .drummer-body {
    height: 4.36em;
    margin-top: 0em;
    margin-bottom: -9em;
  }

  .drummer-right-arm {
    height: 4.5em;
    margin-top: -6.4em;
    margin-bottom: 4em;
    margin-left: .75em;
  }

  .drummer-left-arm {
    height: 3.25em;
    margin-top: -7.8em;
    margin-bottom: 6em;
    margin-left: 1.5em;
  }

  /* drums */
  .drums {
    height: 6em;
    margin-top: -13.75em;
    margin-bottom: 8.3em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -8.15em;
    margin-bottom: 0em;
    height: 6.75em;
  }
}


@media screen and (max-width: 500px) {

  .mainstream-paper {
    background-size: 20em;
    /* background-position: 50% 10%; */
    height: 45.2em;
  }

  #mainstream-div h2 {
    font-size: 28px;
  }

  .announcement-grid {
    margin-top: 16em;
    margin-left: .6em;
  }
  
  .announcement-plain-text{
    font-size: 20px;
  }
  
  .announcement-headline {
    font-size: 36px;
  }
  
  .announcement-sub-headline {
    font-size: 32px;
  }

  /* guitarist */
  .guitarist-skull{
    height: 2.25em; 
    margin-top: 3em;
    margin-bottom: 0em;
  }

  .guitarist-body {
    height: 6.25em;
    margin-top: -2.75em;
    margin-bottom: 0em;
  }

  .guitarist-right-arm {
    height: 2.5em;
    margin-top: -6.6em;
    margin-bottom: 5em;
    margin-left: -2.75em;
  }

  .guitarist-left-arm {
    height: 2em;
    margin-top: -8.1em;
    margin-bottom: 9em;
    margin-left: 4.65em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 2.35em;
    margin-top: -15.3em;
    margin-bottom: 15.25em;
    margin-left: .75em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 3.5em;
    margin-top: 3.5em;
    margin-bottom: 0em;
    margin-left: -.24em;
  }

  .drummer-body {
    height: 3.5em;
    margin-top: -1em;
    margin-bottom: 0em;
  }

  .drummer-right-arm {
    height: 3.5em;
    margin-top: -11.25em;
    margin-bottom: 9em;
    margin-left: .5em;
  }

  .drummer-left-arm {
    height: 2.5em;
    margin-top: -12.2em;
    margin-bottom: 12em;
    margin-left: 1.26em;
  }

  /* drums */
  .drums {
    height: 4.8em;
    margin-top: -18.2em;
    margin-bottom: 15em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -15em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }

}

@media screen and (max-width: 400px) {

  #mainstream-div h2 {
    font-size: 26px;
  }

  .mainstream-paper {
    height: 45.2em;
  }

  /* guitarist */
  /* .guitarist-skull{
    height: 4.75em;
    margin-top: 0em;
    margin-bottom: -10.75em;
    margin-left: 0.1em;
  } */

  /* .guitarist-body {
    height: 11em;
    margin-top: 0em;
  } */

  .guitarist-right-arm {
    height: 2.5em;
    margin-top: -6.61em;
    margin-bottom: 5em;
    margin-left: -2.85em;
  }

  .guitarist-left-arm {
    height: 2em;
    margin-top: -8.1em;
    margin-bottom: 9em;
    margin-left: 4.45em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 2.15em;
    margin-top: -15.3em;
    margin-bottom: 15em;
    margin-left: .75em; 
  }

  /* drummer */
  /* #mohawk-skull {
    height: 7em;
    margin-top: 5em;
    margin-bottom: -6em;
    margin-left: -0.1em;
  } */

  /* .drummer-body {
    height: 8em;
    margin-top: 0em;
    margin-bottom: -13em;
    margin-left: -0.2em;
  } */

  /* .drummer-right-arm {
    height: 6.5em;
    margin-top: -12.75em;
    margin-bottom: 20em;
    margin-left: 0.25em;
  } */

  .drummer-left-arm {
    height: 2.5em;
    margin-top: -12.18em;
    margin-bottom: 12em;
    margin-left: 1.26em;
  }

  /* drums */
  .drums {
    height: 4.8em;
    margin-top: -17.8em;
    margin-bottom: 15em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -14.98em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }
}

@media screen and (max-width: 375px) {

  .mainstream-paper {
    background-size: 18em;
    /* background-position: 50% 8%; */
    height: 43em;
  }

  .announcement-grid {
    margin-top: 14.25em;
  }
  
  .announcement-plain-text{
    font-size: 20px;
  }
  
  .announcement-headline {
    font-size: 34px;
  }
  
  .announcement-sub-headline {
    font-size: 30px;
  }

  /* guitarist */
  /* .guitarist-skull{
    height: 4.75em;
    margin-top: 0em;
    margin-bottom: -10.75em;
    margin-left: 0.1em;
  } */

  /* .guitarist-body {
    height: 11em;
    margin-top: 0em;
  } */

  /* .guitarist-right-arm {
    height: 5em;
    margin-top: -2em;
    margin-bottom: 2em;
    margin-left: -5.75em;
  } */

  .guitarist-left-arm {
    height: 2em;
    margin-top: -8em;
    margin-bottom: 9em;
    margin-left: 4.15em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 2.15em;
    margin-top: -18em;
    margin-bottom: 15em;
    margin-left: 0.65em; 
  }

  /* drummer */
  /* #mohawk-skull {
    margin-left: -.24em;
  } */

  /* .drummer-body {
    height: 8em;
    margin-top: 0em;
    margin-bottom: -13em;
    margin-left: -0.2em;
  } */

  /* .drummer-right-arm {
    height: 6.5em;
    margin-top: -12.75em;
    margin-bottom: 20em;
    margin-left: 0.25em;
  } */

  .drummer-left-arm {
    height: 2.5em;
    margin-top: -12.3em;
    margin-bottom: 13em;
    margin-left: 1.4em;
  }

  /* drums */
  .drums {
    height: 4.6em;
    margin-top: -17.6em;
    margin-bottom: 15em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -14.95em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }

}

@media screen and (max-width: 350px){

  .mainstream-paper {
    background-size: 15.5em;
    background-position: 50% 3%;
    height: 36.8em;
  }

  .announcement-grid {
    margin-top: 11.25em;
    margin-left: .5em;
  }

  .announcement-sub-headline {
    font-size: 29px;
  }

  /* guitarist */
  .guitarist-skull{
    height: 2em; 
    margin-top: 1em;
    margin-bottom: 0em;
  }

  .guitarist-body {
    height: 5.35em;
    margin-top: -2.35em;
    margin-bottom: 0em;
  }

  .guitarist-right-arm {
    height: 2.35em;
    margin-top: -5.77em;
    margin-bottom: 5.77em;
    margin-left: -2.25em;
  }

  .guitarist-left-arm {
    height: 1.8em;
    margin-top: -8.65em;
    margin-bottom: 9em;
    margin-left: 3.78em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 1.9em;
    margin-top: -14.8em;
    margin-bottom: 15em;
    margin-left: 0.75em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 2.9em;
    margin-top: 1.5em;
    margin-bottom: 0em;
    margin-left: -.18em;
  }

  .drummer-body {
    height: 3em;
    margin-top: -1em;
    margin-bottom: 0em;
  }

  .drummer-right-arm {
    height: 2.85em;
    margin-top: -11.2em;
    margin-bottom: 10em;
    margin-left: .25em;
  }

  .drummer-left-arm {
    height: 2.3em;
    margin-top: -12.75em;
    margin-bottom: 12em;
    margin-left: .95em;
  }

  /* drums */
  .drums {
    height: 4em;
    margin-top: -17.6em;
    margin-bottom: 15em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -14.95em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }

}

@media screen and (max-width: 325px){

  .mainstream-paper {
    background-size: 16em;
    /* background-position: 50% 8%; */
    height: 37.1em;
  }

  .announcement-grid {
    margin-top: 11.75em;
    margin-left: .66em;
  }
  
  .announcement-plain-text{
    font-size: 22px;
  }
  
  .announcement-headline {
    font-size: 32px;
  }
  
  .announcement-sub-headline {
    font-size: 26px;
  }

  /* guitarist */
  /* .guitarist-skull{
    height: 4.75em;
    margin-top: 0em;
    margin-bottom: -10.75em;
    margin-left: 0.1em;
  } */

  /* .guitarist-body {
    height: 11em;
    margin-top: 0em;
  } */

  .guitarist-right-arm {
    /* height: 2.35em; */
    margin-top: -5.73em;
    /* margin-bottom: 5.77em; */
    /* margin-left: -2.15em; */
  }

  .guitarist-left-arm {
    height: 1.7em;
    margin-top: -8.7em;
    margin-bottom: 9em;
    margin-left: 3.5em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 1.8em;
    margin-top: -14.75em;
    margin-bottom: 15em;
    margin-left: 0.65em; 
  }

  /* drummer */
  /* #mohawk-skull {
    height: 7em;
    margin-top: 5em;
    margin-bottom: -6em;
    margin-left: -0.1em;
  } */

  /* .drummer-body {
    height: 8em;
    margin-top: 0em;
    margin-bottom: -13em;
    margin-left: -0.2em;
  } */

  /* .drummer-right-arm {
    height: 6.5em;
    margin-top: -12.75em;
    margin-bottom: 20em;
    margin-left: 0.25em;
  } */

  .drummer-left-arm {
    height: 2.3em;
    margin-top: -12.65em;
    margin-bottom: 12em;
    margin-left: .95em;
  }

  /* drums */
  .drums {
    height: 3.75em;
    margin-top: -17.2em;
    margin-bottom: 15em;
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -14.95em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }

}

@media screen and (max-width: 300px) {

  .mainstream-paper {
    background-size: 15em;
    height: 34.55em;
  }

  .announcement-grid {
    margin-top: 11.25em;
    margin-left: .6em;
  }

  .announcement-plain-text{
    font-size: 18px;
  }
  
  .announcement-headline {
    font-size: 30px;
  }
  
  .announcement-sub-headline {
    font-size: 24px;
  }

  /* guitarist */
  .guitarist-skull{
    height: 1.6em; 
    margin-top: 1em;
    margin-bottom: 0em;
  }

  .guitarist-body {
    height: 4.6em;
    margin-top: -2.15em;
    margin-bottom: 0em;
  }

  .guitarist-right-arm {
    height: 2em;
    margin-top: -5.02em;
    margin-bottom: 5.02em;
    margin-left: -1.8em;
  }

  .guitarist-left-arm {
    height: 1.56em;
    margin-top: -7.55em;
    margin-bottom: 9em;
    margin-left: 3.18em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 1.6em;
    margin-top: -14.5em;
    margin-bottom: 15em;
    margin-left: 0.65em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 2.4em;
    margin-top: 1.5em;
    margin-bottom: 0em;
    margin-left: -.21em;
  }

  .drummer-body {
    height: 2.6em;
    margin-top: -1em;
    margin-bottom: 0em;
  }

  .drummer-right-arm {
    height: 2.5em;
    margin-top: -10.95em;
    margin-bottom: 10em;
    margin-left: 0em;
  }

  .drummer-left-arm {
    height: 2em;
    margin-top: -12.65em;
    margin-bottom: 12em;
    margin-left: .85em;
  }

  /* drums */
  .drums {
    height: 3.5em;
    margin-top: -17.25em;
    /* margin-bottom: 0em; */
  }

  /* audio-player */
  .mainstream-react-h5-audio-player {
    margin-top: -14.95em;
    /* margin-bottom: 0em; */
    /* height: 6.75em; */
  }
}

@media screen and (max-width: 280px) {

  .mainstream-paper {
    background-size: 13em;
    /* background-position: 50% 5%; */
    height: 31.2em;
  }

  #mainstream-div h2 {
    font-size: 22px;
  }

  .announcement-grid {
    margin-top: 9.8em;
  }
  
  .announcement-plain-text{
    font-size: 16px;
  }
  
  .announcement-headline {
    font-size: 26px;
  }
  
  .announcement-sub-headline {
    font-size: 20px;
  }

  /* guitarist */
  .guitarist-skull{
    height: 1.4em; 
    margin-top: 1em;
    margin-bottom: 0em;
  }

  .guitarist-body {
    height: 4em;
    margin-top: -1.9em;
    margin-bottom: 0em;
  }

  .guitarist-right-arm {
    height: 1.7em;
    margin-top: -4.45em;
    margin-bottom: 5.02em;
    margin-left: -1.8em;
  }

  .guitarist-left-arm {
    height: 1.4em;
    margin-top: -7.3em;
    margin-bottom: 9em;
    margin-left: 2.88em;
  }

  /* guitars */
  .mainstream-guitar{
    height: 1.4em;
    margin-top: -14.3em;
    margin-bottom: 15em;
    margin-left: 0.65em; 
  }

  /* drummer */
  #mohawk-skull {
    height: 2em;
    margin-top: 1.5em;
    margin-bottom: 0em;
    margin-left: -.14em;
  }

  .drummer-body {
    height: 2.2em;
    margin-top: -.8em;
    margin-bottom: 0em;
  }

  .drummer-right-arm {
    height: 2.28em;
    margin-top: -10.7em;
    margin-bottom: 10em;
    margin-left: 0.2em;
  }

  .drummer-left-arm {
    height: 1.7em;
    margin-top: -12.48em;
    margin-bottom: 12em;
    margin-left: .67em;
  }

  /* drums */
  .drums {
    height: 2.8em;
    margin-top: -16.9em;
    /* margin-bottom: 0em; */
  }

  /* audio player */
  .rhap_volume-button {
    display: none;
  }

  .rhap_volume-bar {
    display: none;
  }

  .rhap_repeat-button {
    display: none;
  }

  .mainstream-react-h5-audio-player {
    margin-top: -14.95em;
  }
}