#album-tracks-section {
  margin-top: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

/* .album-patreon-link {
  color: black;
  font-size: 20px;
  font-weight: bold;
  text-shadow:
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
  cursor: url('../../assets/cursor/sym57.cur'), auto;
} */

.album-share-paper {
  height: 5.5em;
}

.audio-share-box {
  margin-bottom: 1em;
}

.track-title {
  color: black;
  font-size: 24px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

.track-original-artist {
  color: black;
  font-size: 24px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-top: -1.35em;
}

.track-location {
  color: black;
  font-size: 24px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-top: -1.6em;
}

/* .track-location-grid { */
  /* margin-bottom: -14.5em; */
/* } */

.track-download {
    cursor: url('../../assets/cursor/sym57.cur'), auto;
    color: blue;
    margin-top: -.5em;
    margin-bottom: -1.25em;
}

.download-title-msg {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin-top: -15em;
  color: black;
  text-shadow:
  /* 0px -2px 4px #fff, */
  0px -2px 10px #FF3,
  0px -10px 20px #F90,
  0px -20px 40px #C33;
}

.album-tracks-react-h5-audio-player {
  outline: black solid 1px;
  margin-top: -.95em;
  margin-bottom: -2.5em;
}

.rhap_header {
  color: blue;
}

.rhap_footer {
  color: blue;
}

.rhap_container svg  {
  cursor: url('../../assets/cursor/sym57.cur'), auto;
}

@media screen and (max-width: 320px) {
  .album-patreon-link {
    Font-size: 18px;
  }
}

@media screen and (max-width: 300px) {

  #track-title {
    font-size: 1.25em;
  }
  
  #track-original-artist {
    font-size: 1.25em;
  }
  
  #track-location {
    font-size: 1.25em;
  }

  .album-tracks-react-h5-audio-player .rhap_header {
    margin-bottom: 0.1em;
  }

  .album-tracks-react-h5-audio-player.rhap_progress-container {
    height: 0px;   
  }

  .album-tracks-react-h5-audio-player .rhap_progress-indicator {
    display: none;
  }

  .album-tracks-react-h5-audio-player .rhap_additional-controls {
    display: none;
  }

  .album-tracks-react-h5-audio-player .rhap_volume-indicator {
    display: none;
  }

  .album-tracks-react-h5-audio-player .rhap_volume-button {
    display: none;
  }

  .album-tracks-react-h5-audio-player .rhap_volume-bar {
    display: none;
  }

  .album-tracks-react-h5-audio-player .rhap_main-controls {
    margin-left: .75em;
  }

}

@media screen and (max-width: 280px) {
  .album-patreon-link {
    Font-size: 16px;
  }
}